import { O as OwnerForm } from "./index17.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          staticClass: "edit-owner-modal",
          attrs: {
            "title": "Edit Owner",
            "after-close": _vm.afterModalClose,
            "width": "680px"
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                key: "sync",
                attrs: {
                  "icon": "reload",
                  "loading": _vm.isSync
                },
                on: {
                  "click": _vm.onSync
                }
              }, [_vm._v("Sync From AD")]), _c("a-button", {
                key: "cancel",
                on: {
                  "click": _vm.onCancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                key: "submit",
                attrs: {
                  "type": "primary",
                  "loading": _vm.isLoading
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.onSave);
                  }
                }
              }, [_vm._v("Save")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v;
            },
            expression: "visible"
          }
        }, [_c("div", {
          staticClass: "edit-owner-modal__content"
        }, [_c("owner-form", {
          attrs: {
            "is-sync": _vm.isSync,
            "owner-data": _vm.owner
          },
          on: {
            "update:isSync": function($event) {
              _vm.isSync = $event;
            },
            "update:is-sync": function($event) {
              _vm.isSync = $event;
            }
          }
        }), _c("a-row", [_c("a-col", {
          staticClass: "b8 neutral-7--text",
          attrs: {
            "span": 24
          }
        }, [_vm._v("Last modified by " + _vm._s(_vm.owner.updatedBy) + " on " + _vm._s(_vm._f("moment")(_vm.owner.updatedAt, "DD/MM/YYYY HH:mm")))])], 1)], 1)])];
      }
    }])
  });
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditOwner",
  inject: ["resourceProps"],
  components: {
    OwnerForm
  },
  data() {
    const [ownerProps] = this.resourceProps;
    const ownerId = this.$route.params.id;
    return {
      apiUrl: apiUrl$1,
      ownerProps,
      isLoading: false,
      visible: true,
      isSync: false,
      owner: {},
      ownerId
    };
  },
  mounted() {
    this.owner = this.ownerProps.crud.getEntity(this.ownerId) || {};
  },
  methods: {
    onSave() {
      this.saveChanges();
    },
    async saveChanges() {
      try {
        this.isLoading = true;
        await this.ownerProps.crud.submitEntity("replace");
        this.visible = false;
      } finally {
        this.isLoading = false;
      }
    },
    onSync() {
      this.isSync = true;
    },
    onCancel() {
      this.visible = false;
    },
    afterModalClose() {
      this.ownerProps.crud.clearEntity("editForm");
      this.$router.push(this.ownerProps.redirectRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditOwner = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-owner"
  }, [_c("resource", {
    attrs: {
      "name": ["export-order.manage-owners"],
      "api-url": _vm.apiUrl,
      "edit-route-id-name": "id",
      "redirect-route": ["/export-orders/owner"]
    }
  }, [_c("edit-owner")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditOwnerIndex",
  components: {
    EditOwner
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
